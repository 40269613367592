@import "~vanilla-cookieconsent/dist/cookieconsent.css";

//COOKIECONSENT custom styles
:root {
    --cc-bg: #ffffff;
    --cc-text: #333333;
    --cc-btn-primary-bg: #0b9edb;
    --cc-btn-primary-hover-bg: #004b91;
    --cc-btn-secondary-bg: #fefefe;
    --cc-btn-secondary-hover-bg: #f8f8f8;
}

.cc_div .c-bn,
.cc_div .b-tl,
.cc_div #s-ttl,
.cc_div #c-ttl,
.cc_div #s-bl td:before {
    color: var(--cc-btn-primary-bg);
}

.cc_div .c-bn {
    font-size: 0.90em;
    border-radius: 0;
}

.cc_div #cm,
.cc_div #s-inr,
.cc_div .c-bl,
.cc_div .b-tl,
.cc_div #s-bl .act .b-acc {
    border-radius: 0;
}

.cc_div .cc-link:active, 
.cc_div .cc-link:hover {
    color: var(--cc-btn-primary-hover-bg);
    border-color: var(--cc-btn-primary-hover-bg);
}

.cc_div #c-bns button:first-child,
.cc_div #s-bns button:first-child {
    border: 1px solid var(--cc-btn-primary-bg);
}

.cc_div #c-bns button:first-child:hover,
.cc_div #s-bns button:first-child:hover {
    border: 1px solid var(--cc-btn-primary-hover-bg);
}

.cc_div table tr:last-child {
    border-bottom: none;
}