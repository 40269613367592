@import "~lightgallery/dist/css/lightgallery.min.css";
@import "cookieconsent-custom";

.download-link {
  display: block;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  padding: 20px 0;
  margin-bottom: 20px;
  border-radius: 3px;
  background-color: #ad131f;
  color: #fff;
  border: 2px solid #ad131f;

}
.download-link:hover {
  background-color: #fff;
  color: #ad131f;
}

.download-link span {
  text-decoration: none;
  line-height: 24px;
  font-size: 16px
}

.download-link .title {
  display: block;
  width: 100%;
  padding: 0 15px;
  text-align: center;
  font-size: 18px;
  line-height: 25px;
  height: auto;
  min-height: 85px
}

//.download-link .icon {
//  position: relative;
//  top: 1px;
//  display: block;
//  float: left;
//  width: 16%;
//  text-align: center;
//  white-space: nowrap;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  font-size: 20px
//}

.download-link .filename {
  display: block;
  float: left;
  width: 74%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 15px;
}

.download-link .size {
  display: block;
  float: right;
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 15px;
}

.download-link .sufix:before,.download-link .sufix:after {
  content: " ";
  display: table
}

.download-link .sufix:after {
  clear: both
}